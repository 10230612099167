/*
 * @Author: “mxmxmx” “2454023350@qq.com”
 * @Date: 2024-07-22 15:19:56
 * @LastEditors: “mxmxmx” “2454023350@qq.com”
 * @LastEditTime: 2024-08-10 17:06:56
 * @FilePath: /vue-horhead/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jquery from "jquery";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import "@/utils/initwalletConnect";
// import { WalletMultiButton } from "solana-wallets-vue-2";
// import "solana-wallets-vue-2/styles.css";
// Vue.component("wallet-multi-button", WalletMultiButton);
Vue.use(ElementUI);

Vue.prototype.$ = jquery;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
