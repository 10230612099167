<template>
  <div class="minepage" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="header">
      <div class="box1">
        <img class="img1" src="../static/img/header1.png" />
      </div>
      <div class="box2">
        <router-link :to="{ path: '/home?href=0&sectionname=section1' }">
          <p class="text1">ABOUT</p>
        </router-link>
        <i class="text5">/</i>
        <router-link :to="{ path: '/home?href=1&sectionname=section2' }">
          <p class="text2">HOW TO GET</p>
        </router-link>
        <i class="text5">/</i>
        <router-link :to="{ path: '/home?href=2&sectionname=section3' }">
          <p class="text3">CONTACT</p>
        </router-link>
        <i class="text5">/</i>
        <p class="text4">MY TEAM</p>
        <img
          style="cursor: pointer"
          @click="openurl('https://t.me/HorsHEAD')"
          class="img2"
          src="../static/img/header2.png"
        />
        <img
          style="cursor: pointer"
          @click="openurl('https://x.com/horseheadonSOL')"
          class="img2"
          src="../static/img/header3.png"
        />
        <img class="img2" src="../static/img/header6.png" />
        <img class="img2" src="../static/img/header4.png" />
        <img class="img2" src="../static/img/header5.png" />
      </div>
    </div>
    <div class="content">
      <div class="box3" v-if="address">
        <p @click="disconnectwl">Disconnect</p>
        <img src="../static/img/img5.png" />
        <!-- <test ref="test"></test> -->
      </div>
      <div class="box3" v-else>
        <p @click="connectwallet">CONNECT WALLET</p>
        <img src="../static/img/img5.png" />
      </div>
      <div class="box4">
        <div class="box41">
          <div class="box411">
            <img src="../static/img/img6.png" />
            <div class="box4111">
              <p class="text7">{{ address }}</p>
              <div class="box41111">
                <p class="text6">My invitation code:</p>
                <p class="text7">{{ Invitecode }}</p>
                <p
                  class="text8"
                  @click="copyInvitecode"
                  style="cursor: pointer"
                >
                  COPY
                </p>
              </div>
              <div class="box41112">
                <p class="text6">I donated:</p>
                <p class="text7">{{ money }} SOL</p>
              </div>
            </div>
          </div>
          <div class="box412">
            <p>bonus</p>
            <img src="../static/img/img7.png" />
            <p>*{{ bonus.toFixed(4) }}</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="box42">
          <div class="box421">
            <p class="text6">Direct Invitations</p>
            <p class="text9">{{ direct_count }}</p>
          </div>
          <div class="box421">
            <p class="text6">TEAM Invitations</p>
            <p class="text9">{{ team_count }}</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="box42">
          <div class="box421">
            <p class="text6">Total Donations from Direct Invitations</p>
            <p class="text9">{{ direct_sol_amount }}</p>
          </div>
          <div class="box421">
            <p class="text6">Total Donations from Team Invitations</p>
            <p class="text9">{{ team_sol_amount }}</p>
          </div>
        </div>
      </div>
      <div class="box5">
        <p class="text14">Check the donation status of direct invitees</p>
        <div class="box51121">
          <input
            v-model="useraddress"
            type="text"
            placeholder="Enter the address to query"
          />
          <p style="cursor: pointer" @click="searchuserlist">Search</p>
        </div>
        <div class="line"></div>
        <div class="box51">
          <p class="text6 text10">ID</p>
          <p class="text6 text12">Address</p>
          <p class="text6 text11">Donation Amount</p>
        </div>
        <div v-if="issearch">
          <div v-if="userlist.length != 0">
            <div v-for="item in userlist">
              <div class="line"></div>
              <div class="box51">
                <p class="text6 text10">{{ item.ID }}</p>
                <p class="text6 text12">{{ item.address }}</p>
                <p class="text6 text11">{{ item.sol_amount }}</p>
              </div>
            </div>
          </div>

          <p v-else class="text13">
            Sorry, no relevant donation information was found, or this user is
            not directly invited by you.
          </p>
        </div>
      </div>
    </div>
    <div v-show="islogin" class="hidebox">
      <div class="hidebox1">
        <p>
          Please connect your wallet <br />
          to view your personal dashboard.
        </p>
      </div>
    </div>
    <div class="footer">contact@horsh.fun</div>
  </div>
</template>
<script>
import { getuserinfo, postsearch } from "@/api/minepage";
// import { modal, config } from "@/utils/initwalletConnect";
import { Login } from "@/api/homepage.js";
import {
  getCode,
  setCode,
  removeCode,
  setToken,
  setRole,
  setUId,
  removeToken,
  removeUId,
  removeRole,
  getToken,
  getUId,
  getMoney,
  setMoney,
  getRole,
  setIsConnected,
  getIsConnected,
  removeIsConnected,
} from "@/utils/auth";
// import {
//   getAccount, //获取账户信息
//   disconnect, //断开链接
//   watchAccount, //监听账户状态
//   getChainId, //获取链条id
//   switchChain, //切换链条
//   readContract, //读取abi文件
// } from "@wagmi/core";
// import { onSignMessage } from "@/utils/walletActions";
import { Message } from "element-ui";
import { copyString } from "@/utils";
export default {
  name: "MinePage",
  data() {
    return {
      unwatch: null,
      fullscreenLoading: false,
      useraddress: "",
      useraddress2: "",
      Invitecode: "",
      direct_count: 0,
      direct_sol_amount: 0,
      team_count: 0,
      team_sol_amount: 0,
      userlist: [],
      issearch: false,
      address: "",
      money: 0,
      islogin: true,
      bonus: 0,
    };
  },
  created() {},
  async mounted() {
    let self = this;
    // const unwatch = watchAccount(config, {
    //     async onChange(account) {
    //         console.log(account);
    //         if (account.status == "connected" && account.isConnected) {
    //             self.walletInfo = getAccount(config);
    //             const message = "how are you";
    //             const signMsg = await onSignMessage(self.walletInfo.address, message);
    //             //此处判断已经登录就不用签名再登录了
    //             self.loginAccount(self.walletInfo.address, message, signMsg);
    //         }
    //     },
    // });
    if (window.phantom) {
      if (!getIsConnected()) {
        return;
      } else {
        if (!window.phantom.solana.isConnected) {
          let pb = await window.phantom.solana.connect();
          this.address = pb.publicKey.toBase58();
          // this.address = await window.phantom.solana
          //   .connect()
          //   .publicKey.toBase58();
        }
      }
    }
    if (getUId() != undefined) {
      this.islogin = false;
      console.log(getUId());
      this.Invitecode = getCode();
      this.money = getMoney();
      this.address = getRole();
      getuserinfo(getUId()).then((res) => {
        console.log(res);
        this.direct_count = res.data.direct_count;
        this.direct_sol_amount = res.data.direct_sol_amount;
        this.team_count = res.data.team_count;
        this.team_sol_amount = res.data.team_sol_amount;
        this.bonus = res.data.bonus;
      });
    }
  },
  methods: {
    out() {
      removeToken();
      removeUId();
      removeRole();
      removeCode();
      removeIsConnected();
      console.log("退出");
      this.address = "";
      this.Invitecode = "*****";
    },
    disconnectwl() {
      if (window.phantom) {
        window.phantom.solana.disconnect();
        this.out();
      } else if (window.okxwallet) {
        if (window.phantom) {
          return;
        }
        window.okxwallet.solana.disconnect();
        this.out();
      }
    },
    async connectwallet() {
      //   modal.open();
      if (window.phantom) {
        if (!window.phantom.solana.isConnected) {
          let pb = await window.phantom.solana.connect();
          const pbaddress = pb.publicKey.toBase58();
          const original_msg = `As fren of PEPE, his excellency(h) HORSH is the undisputed meme
captain with a distinctive buck-toothed grin and a unique way of speaking
that turns "Horse" into "Horsh." A chatterbox at heart, he loves reggae music
and never misses a chance to boast about his incredible race achievements. His
catchphrase? “I am the only unparalleled, dazzling horsh you can't deny ”`;
          const { signature, publicKey } = await window.solana.signMessage(
            new TextEncoder().encode(original_msg),
            "utf8"
          );
          console.log(signature, publicKey);
          this.loginAccount(pbaddress, original_msg, signature);
          // this.address = await window.phantom.solana
          //   .connect()
          //   .publicKey.toBase58();
        }
      }
      if (window.okxwallet) {
        if (window.phantom) {
          return;
        }
        const cnres = await window.okxwallet.solana.connect();
        if (cnres.publicKey) {
          const pbaddress = cnres.publicKey.toBase58();
          const provider = window.okxwallet?.solana;
          const original_msg = `As fren of PEPE, his excellency(h) HORSH is the undisputed meme
captain with a distinctive buck-toothed grin and a unique way of speaking
that turns "Horse" into "Horsh." A chatterbox at heart, he loves reggae music
and never misses a chance to boast about his incredible race achievements. His
catchphrase? “I am the only unparalleled, dazzling horsh you can't deny ”`;
          const { signature, publicKey } =
            await window.okxwallet.solana.signMessage(
              new TextEncoder().encode(original_msg),
              "utf8"
            );
          console.log(signature, publicKey);
          this.loginAccount(pbaddress, original_msg, signature);
        }
      }
    },
    loginAccount(address, original_msg, signature) {
      Login({
        address,
        original_msg,
        signature_hex: JSON.stringify(signature),
      }).then((res) => {
        console.log(res);
        setToken(res.data.accessToken);
        setRole(address);
        setUId(res.data.User_id);
        setMoney(res.data.sol_amount);
        setIsConnected(1);
        this.Invitecode = getCode();
        this.money = getMoney();
        this.address = address;
        this.islogin = false;
        if (res.data.code != "") {
          setCode(res.data.code);
          this.Invitecode = res.data.code;
        }
        getuserinfo(getUId()).then((res) => {
          console.log(res);
          this.direct_count = res.data.direct_count;
          this.direct_sol_amount = res.data.direct_sol_amount;
          this.team_count = res.data.team_count;
          this.team_sol_amount = res.data.team_sol_amount;
          this.bonus = res.data.bonus;
        });
      });
    },
    openurl(url) {
      window.open(url, "_blank");
    },
    searchuserlist() {
      if (getUId() == undefined) {
        return;
      }
      if (this.useraddress == "" || this.useraddress == this.useraddress2) {
        return;
      }
      this.fullscreenLoading = true;
      postsearch({
        address: this.useraddress,
        user_id: getUId(),
      }).then((res) => {
        console.log(res);
        this.useraddress2 = this.useraddress;
        if (res.data.ID != undefined) {
          this.userlist = [res.data];
        }
        this.issearch = true;
        this.fullscreenLoading = false;
      });
    },
    async copyInvitecode() {
      if (this.Invitecode == "") {
        return;
      } else {
        try {
          await navigator.clipboard.writeText(this.Invitecode);
          Message({
            message: "Success",
            type: "success",
            duration: 3 * 1000,
          });
          // 可以在这里添加复制成功的提示
        } catch (err) {
          Message({
            message: "error",
            type: "error",
            duration: 3 * 1000,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "font";
  src: url("../static/font/font.ttf") format("truetype");
}
@font-face {
  font-family: "font";
  src: url("../static/font/font.ttf") format("truetype");
}
.minepage {
  font-family: "font";
  font-size: 1.6rem;
  background-color: black;
}

.hidebox {
  position: absolute;
  top: 45rem;
  left: 0;
  height: 134rem;
  width: 100%;
}

.hidebox1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: math;
  color: #ffffff;
  font-style: italic;
  height: 100%;
  margin: 0 14rem;
  font-size: 5rem;
  text-align: center;
  line-height: 8rem;
  background-color: rgba(0, 0, 0, 0.3);
  /* 半透明背景 */
  backdrop-filter: blur(10px);
  /* 背景模糊 */
  -webkit-backdrop-filter: blur(10px);
  /* 兼容老版本 Safari */
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 6rem;
  background-color: #dddddd;
}

/* 当屏幕宽度大于或等于768像素时，改为浮动布局 */
@media (min-width: 768px) {
  .minepage {
    min-width: 190rem;
  }
  .header {
    min-width: 190rem;
  }

  .hidebox {
    min-width: 190rem;
  }
}
@media (max-width: 768px) {
  .minepage {
    height: 100vh;
  }
  .footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

a {
  text-decoration: none;
}

.box1 {
  margin-left: 10rem;
}

.box2 {
  margin-right: 10rem;
  display: flex;
  align-items: center;
}

.box2 p {
  margin: 0 2rem;
  font-style: italic;
}

.img1 {
  width: 40rem;
  height: 11rem;
}

.img2 {
  width: 6rem;
  height: 6rem;
  margin: 0 0.3rem;
}

.text1 {
  cursor: pointer;
  color: white;
}

.text2 {
  cursor: pointer;
  color: white;
}

.text3 {
  cursor: pointer;
  color: white;
}

.text4 {
  cursor: pointer;
  color: black !important;
}

.text5 {
  color: white;
}
.text7 {
  font-family: Thunder !important;
  font-weight: bold;
}
.content {
  padding: 20rem 10rem 11rem;
}

.box3 {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5rem 0;
  position: relative;
}

.box3 p {
  margin-right: 9rem;
  width: 44rem;
  height: 11rem;
  line-height: 11rem;
  font-size: 6rem;
  background-color: #fff;
  padding-left: 3rem;
  font-style: italic;
  cursor: pointer;
}

.box3 img {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
}

.box4 {
  background: rgba(82, 82, 82, 0.95);
  padding: 7rem;
}

.box41 {
  display: flex;
  justify-content: space-between;
}

.box411 {
  display: flex;
  align-items: center;
}

.box411 img {
  width: 13rem;
  height: 13rem;
  margin-right: 3rem;
}

.box4111 {
  display: flex;
  flex-direction: column;
}

.box41111 {
  display: flex;
}

.box41112 {
  display: flex;
}

.box412 {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
}

.box412 p {
  font-size: 8rem;
  color: #f9f9f9;
  line-height: 10rem;
  font-style: italic;
}

.box412 img {
  margin-left: 2rem;
  width: 6.5rem;
  height: 6.5rem;
}

.text6 {
  padding-right: 1rem;
  font-size: 5rem;
  color: #f9f9f9;
  line-height: 6rem;
  font-style: italic;
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
}

.text7 {
  font-size: 5rem;
  color: #f9f9f9;
  line-height: 6rem;
  font-style: italic;
  font-weight: bold;
}

.text8 {
  margin-left: 2rem;
  padding: 0 2rem 0 1rem;
  width: 8rem;
  font-weight: bold;
  text-align: center;
  height: 5rem;
  background: #ebf7fc;
  font-size: 4rem;
  color: #000000;
  line-height: 5.5rem;
  font-style: italic;
  margin-top: 0.5rem;
}

.box42 {
  display: flex;
  justify-content: space-between;
}

.box421 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text9 {
  font-size: 12rem;
  color: #f9f9f9;
  line-height: 12rem;
  font-style: italic;
}

.line {
  width: 100%;
  height: 4px;
  background: #dddddd;
  margin: 4rem 0;
}

.box5 {
  margin-top: 2rem;
  background: rgba(82, 82, 82, 0.95);
  padding: 7rem;
}

.box51 {
  display: flex;
  align-items: center;
  margin: 0 10rem;
  justify-content: space-between;
}

.text10 {
  width: 5rem;
}

.text12 {
  width: 60%;
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
}

.text11 {
  width: 32rem;
}

.text13 {
  text-align: center;
  margin-top: 10rem;
  font-size: 4rem;
  color: #ffffff;
  line-height: 4rem;
  font-style: italic;
}

.text14 {
  font-size: 6rem;
  color: #f9f9f9;
  line-height: 7rem;
  font-style: italic;
  text-align: center;
}

.box51121 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  padding-top: 6rem;
  padding-bottom: 3rem;
}

.box51121 input {
  width: 60%;
  height: 11rem;
  font-size: 6rem;
  line-height: 60px;
  text-align: center;
  font-family: math;
}

.box51121 input:focus {
  outline: none;
}

.box51121 input::placeholder {
  color: #cccccc;
  font-family: math;
  font-style: italic;
}

.box51121 p {
  width: 30%;
  height: 11rem;
  font-size: 8rem;
  color: #ffff00;
  line-height: 11rem;
  font-style: italic;
  background: #5500ba;
  text-align: center;
}

.footer {
  color: #fff;
  text-align: center;
  font-size: 3rem;
  padding: 5rem;
}
</style>
