<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import $ from 'jquery'

export default {
    name: 'App',
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }

}

</script>
<style>
* {
    margin: 0;
    padding: 0;

}

html {
    font-size: 8px;
}

/* 当屏幕宽度小于或等于768像素时，应用以下样式 */
@media (max-width: 768px) {
    html {
        font-size: 2px;
    }
}
</style>
