/*
 * @Author: “mxmxmx” “2454023350@qq.com”
 * @Date: 2024-07-25 12:25:23
 * @LastEditors: “mxmxmx” “2454023350@qq.com”
 * @LastEditTime: 2024-08-10 17:09:45
 * @FilePath: /horsh-web/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from "../page/HomePage.vue";
import MinePage from "../page/MinePage.vue";
import Test from "../page/test.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: HomePage,
  },
  {
    path: "/mine",
    name: "mine",
    component: MinePage,
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
  { path: "*", redirect: "/index" },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
