import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // baseURL: "", //测试环境
  baseURL: "https://horsh.fun", //线上
  // baseURL: '/nft/admin/',
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (getToken() != undefined) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = getToken();
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    console.log(response.data);
    if (response.data.code != 0) {
      Message({
        message: response.data.msg || "Error",
        type: "error",
        duration: 2000,
      });
      return response.data;
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log(error);
    // if(error.toString()=='Error: Network Error'){
    //     Message({
    //         message: '登陆超时，请重新登陆',
    //         type: 'error',
    //         duration: 2000
    //     })
    //     setTimeout(()=>{
    //         store.dispatch('user/resetToken').then(() => {
    //             location.reload()
    //         })
    //     },1500)
    // }else{
    //     Message({
    //         message: error.response.data.msg,
    //         type: 'error',
    //         duration: 5 * 1000
    //     })
    // }
    // console.log('err' + error) // for debug
    // if (error.response.status==401){
    //     Message({
    //         message: '登陆超时，请重新登陆',
    //         type: 'error',
    //         duration: 2000
    //     })
    //     setTimeout(()=>{
    //         // store.dispatch('user/resetToken').then(() => {
    //             // location.reload()
    //         // })
    //     },1500)
    // }else {
    //     Message({
    //         message: error.response.data.msg,
    //         type: 'error',
    //         duration: 5 * 1000
    //     })
    // }
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
