/*
 * @Author: “mxmxmx” “2454023350@qq.com”
 * @Date: 2024-07-25 14:07:48
 * @LastEditors: “mxmxmx” “2454023350@qq.com”
 * @LastEditTime: 2024-07-26 20:15:47
 * @FilePath: /horsh-web/src/api/homepage.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

// {
//     "address": "0x2w323423",
//     "original_msg": "string",
//     "signature_hex": "string"
//   }
export function Login(data) {
  return request({
    url: "/v1/login",
    method: "post",
    data,
  });
}

// {
//     "Code": "string",
//     "user_id": "string"
//   }
export function postInvitecode(data) {
  return request({
    url: "/v1/user/Invitecode",
    method: "post",
    data,
  });
}
