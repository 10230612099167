<template>
  <div class="homepage">
    <div class="header">
      <div class="box1">
        <img class="img1" src="../static/img/header1.png" />
      </div>
      <div class="box2">
        <a class="text1 text0" href="#section1" @click="scrollToAnchor(0)"
          >ABOUT</a
        >
        <i class="text5">/</i>
        <a class="text2 text0" href="#section2" @click="scrollToAnchor(1)"
          >HOW TO GET</a
        >
        <i class="text5">/</i>
        <a class="text3 text0" href="#section3" @click="scrollToAnchor(2)"
          >CONTACT</a
        >
        <i class="text5">/</i>
        <router-link :to="{ path: '/mine' }">
          <p class="text4">MY TEAM</p>
        </router-link>
        <img
          style="cursor: pointer"
          @click="openurl('https://t.me/HorsHEAD')"
          class="img2"
          src="../static/img/header2.png"
        />
        <img
          style="cursor: pointer"
          @click="openurl('https://x.com/horseheadonSOL')"
          class="img2"
          src="../static/img/header3.png"
        />
        <img class="img2" src="../static/img/header6.png" />
        <img
          class="img2"
          src="../static/img/header4.png"
          style="cursor: pointer"
          @click="
            openurl(
              'https://www.dextools.io/app/cn/solana/pair-explorer/hBua1teJe5AKNwZrFvenhaJEMkBk2P7D9mfBfnYLVKp?t=1722751438324'
            )
          "
        />
        <img
          class="img2"
          src="../static/img/header5.png"
          style="cursor: pointer"
          @click="
            openurl(
              'https://dexscreener.com/solana/hbua1teje5aknwzrfvenhajemkbk2p7d9mfbfnylvkp'
            )
          "
        />
      </div>
    </div>
    <div class="bigima1">
      <img src="../static/img/bigimg1.png" />
      <div class="img7top">Token address：</div>
      <div class="img7">
        <p v-if="showCode">
          A9wX........Qwave<i @click="copyInvitecode2"> / copy</i>
        </p>
        <p v-else>comming soon</p>
      </div>
    </div>
    <div class="box3" id="section1">
      <div class="box31">
        <img class="bigima2" src="../static/img/bigimg2.png" />
        <div>
          As fren of PEPE, his excellency(h) HORSH is the undisputed meme <br />
          captain with a distinctive buck-toothed grin and a unique way of
          speaking <br />
          that turns "Horse" into "Horsh." A chatterbox at heart, he loves
          reggae music <br />
          and never misses a chance to boast about his incredible race
          achievements. His <br />
          catchphrase? “I am the only unparalleled, dazzling horsh you can't
          deny ”
        </div>
      </div>
      <img class="bigima3" src="../static/img/bigimg3.png" />
    </div>
    <div class="box4" id="section2">
      <img class="bigima4" src="../static/img/bigimg4.png" />
      <div class="box41 box42">
        <div class="box411">
          <img src="../static/img/img1.png" />
        </div>
        <div class="box412">
          <img src="../static/img/img11.png" />
          <p>
            Download Phantom or your wallet of choice from the app store or
            google play store for free. Desktop users, download the google
            chrome extension by going to phantom app
          </p>
        </div>
      </div>
      <div class="box41">
        <div class="box411">
          <img src="../static/img/img2.png" />
        </div>
        <div class="box412">
          <img src="../static/img/img22.png" />
          <p>
            You can buy SOL from an exchange or cross chain swap and send it to
            your wallet.
          </p>
        </div>
      </div>
      <div class="box41">
        <div class="box411">
          <img src="../static/img/img3.png" />
        </div>
        <div class="box412">
          <img src="../static/img/img33.png" />
          <p>
            Connect to Raydium. Go raydium.io in google chrome or on the browser
            inside your Phantom app. Connect your wallet. Paste the $HORSH token
            address into Raydium and confirm the swap. When Phantom prompts you
            for a wallet signature, sign.
          </p>
        </div>
      </div>
      <div class="box41">
        <div class="box411">
          <img src="../static/img/img4.png" />
        </div>
        <div class="box412">
          <img src="../static/img/img44.png" />
          <p>Switch SOL for $HORSH</p>
        </div>
      </div>
    </div>
    <div class="box5" id="section3">
      <img class="bigimg5" src="../static/img/bigimg5.png" alt="" />
      <div class="box51">
        <div class="box511">
          <div class="connectedbox">
            <div class="addressbox" v-if="address" @click="copyAddress">
              My Address:
              <span>{{ truncateString(address, 10) }}</span>
            </div>
            <div v-else></div>
            <div class="connect-btn">
              <div class="box5111" @click="disconnectwl" v-if="address">
                <p>Disconnect</p>
                <img src="../static/img/img5.png" />
                <!-- <test ref="test"></test> -->
              </div>
              <div class="box5111" @click="connectwallet" v-else>
                <p>CONNECT WALLET</p>
                <img src="../static/img/img5.png" />
                <!-- <test ref="test"></test> -->
              </div>
            </div>
          </div>

          <div class="box5112">
            <div class="box51121">
              <input
                v-model="invitation"
                type="text"
                placeholder="Enter the invitation code."
              />
              <p style="cursor: pointer" @click="getcode">validate</p>
            </div>
            <p class="box51122">My invitation code</p>
            <div class="box51123">
              <p>{{ Invitecode }}</p>
              <div style="cursor: pointer" @click="copyInvitecode">COPY</div>
              <div class="myteam-btn" @click="$router.push('/mine')">
                My Team
              </div>
            </div>
            <p class="box51124">
              After successfully verifying the invitation code, you can unlock
              your own invitation code.
            </p>
          </div>
        </div>
      </div>
      <div class="box52"></div>
    </div>
    <div class="box6" id="section4" v-show="false">
      <img class="bigimg6" src="../static/img/bigimg6.png" />
      <div class="box61">
        <img class="bigimg7" src="../static/img/bigimg7.png" />
        <div class="box611">
          <div class="box6111">
            <div class="box61111"></div>
            <p>10%</p>
            Liquidity Provider
          </div>

          <div class="box6111">
            <div class="box61112"></div>
            <p>50%</p>
            Marketing
          </div>
          <div class="box6111">
            <div class="box61113"></div>
            <p>10%</p>
            persale
          </div>
          <div class="box6111">
            <div class="box61114"></div>
            <p>10%</p>
            Market Maker
          </div>
          <div class="box6111">
            <div class="box61115"></div>
            <p>10%</p>
            community
          </div>
          <div class="box6111">
            <div class="box61116"></div>
            <p>5%</p>
            ecosystem
          </div>
          <div class="box6111">
            <div class="box61117"></div>
            <p>5%</p>
            airdrop
          </div>
        </div>
        <div class="box612">
          <img
            style="cursor: pointer"
            @click="openurl('https://t.me/HorsHEAD')"
            src="../static/img/header2.png"
          />
          <img
            style="cursor: pointer"
            @click="openurl('https://x.com/horseheadonSOL')"
            src="../static/img/header3.png"
          />
          <img src="../static/img/header6.png" />
          <img src="../static/img/header4.png" />
          <img src="../static/img/header5.png" />
        </div>
      </div>
    </div>
    <div class="pagefooter" style="display: none">
      <img src="../static/img/32.png" class="pagefooter-back" alt="" />
      <div class="pagefooter-box2">
        <img
          style="cursor: pointer"
          @click="openurl('https://t.me/HorsHEAD')"
          class="img2"
          src="../static/img/header2.png"
        />
        <img
          style="cursor: pointer"
          @click="openurl('https://x.com/horseheadonSOL')"
          class="img2"
          src="../static/img/header3.png"
        />
        <img class="img2" src="../static/img/header6.png" />
        <img
          class="img2"
          src="../static/img/header4.png"
          style="cursor: pointer"
          @click="
            openurl(
              'https://www.dextools.io/app/cn/solana/pair-explorer/hBua1teJe5AKNwZrFvenhaJEMkBk2P7D9mfBfnYLVKp?t=1722751438324'
            )
          "
        />
        <img
          class="img2"
          src="../static/img/header5.png"
          style="cursor: pointer"
          @click="
            openurl(
              'https://dexscreener.com/solana/hbua1teje5aknwzrfvenhajemkbk2p7d9mfbfnylvkp'
            )
          "
        />
      </div>
    </div>
    <div class="email">CONTACT@HORSH.FUN</div>
  </div>
</template>
<script>
import { Login, postInvitecode } from "@/api/homepage.js";
import {
  getCode,
  setCode,
  removeCode,
  setToken,
  setRole,
  setUId,
  removeToken,
  removeUId,
  removeRole,
  getToken,
  getUId,
  setMoney,
  setIsConnected,
  getIsConnected,
  removeIsConnected,
} from "@/utils/auth";
import $ from "jquery";
// import { modal, config } from "@/utils/initwalletConnect";
// import {
//   getAccount, //获取账户信息
//   disconnect, //断开链接
//   watchAccount, //监听账户状态
//   getChainId, //获取链条id
//   switchChain, //切换链条
//   readContract, //读取abi文件
// } from "@wagmi/core";
// import { onSignMessage } from "@/utils/walletActions";
import { Message } from "element-ui";
import { copyString } from "@/utils";
// import test from "./test.vue";
export default {
  name: "HomePage",
  data() {
    return {
      unwatch: null,
      walletInfo: {},
      invitation: "",
      invitation2: "",
      Invitecode: "*****",
      address: "",
      showCode: false,
    };
  },
  created() {},
  async mounted() {
    console.log(this.$route.query);
    this.needShowCode();
    if (this.$route.query) {
      if (this.$route.query.href) {
        this.scrollToAnchor(this.$route.query.href);
        const sectionname = this.$route.query.sectionname;
        const scrollDom = document.getElementById(sectionname);
        if (scrollDom) {
          scrollDom.scrollIntoView();
        }
      }
    }
    if (window.okxwallet) {
      if (window.phantom) {
        return;
      }
      if (!getIsConnected()) {
        return;
      } else {
        if (!window.okxwallet.solana.isConnected) {
          //如果没有链接过，链接一次
          let pb = await window.okxwallet.solana.connect();
          this.address = pb.publicKey.toBase58();
          if (getCode() != "undefined") {
            this.Invitecode = getCode();
          }
        } else {
          //如果已经链接获取页面信息
          this.resetpageInfo();
        }
      }
    } else {
      if (window.phantom) {
        if (!getIsConnected()) {
          return;
        } else {
          if (!window.phantom.solana.isConnected) {
            //如果没有链接过，链接一次
            let pb = await window.phantom.solana.connect();
            this.address = pb.publicKey.toBase58();
            if (getCode() != "undefined") {
              this.Invitecode = getCode();
            }
          } else {
            //如果已经链接获取页面信息
            this.resetpageInfo();
          }
        }
      }
    }
    // let self = this;

    // if (getCode() != "undefined") {
    //   this.Invitecode = getCode();
    // } else {
    // }
    // const unwatch = watchAccount(config, {
    //   async onChange(account) {
    //     console.log(account);
    //     if (account.status == "connected" && account.isConnected) {
    //       self.walletInfo = getAccount(config);
    //       const message = "how are you";
    //       const signMsg = await onSignMessage(self.walletInfo.address, message);
    //       //此处判断已经登录就不用签名再登录了
    //       self.loginAccount(self.walletInfo.address, message, signMsg);
    //     }
    //   },
    // });
    // this.unwatch = unwatch;
  },
  methods: {
    copyString,
    needShowCode() {
      // 获取当前时间
      const now = new Date();
      // 设置目标时间（2024 年 8 月 1 日 20 点）
      const targetTime = new Date(2024, 7, 2, 19, 55);

      if (now >= targetTime) {
        this.showCode = true;
      }
    },
    async resetpageInfo() {
      if (getCode() != "undefined") {
        this.Invitecode = getCode();
      }
      let pb = await window.phantom.solana.connect();
      this.address = pb.publicKey.toBase58();
    },
    scrollToAnchor(index) {
      $(".text0")
        .eq(index)
        .css("color", "black")
        .siblings()
        .css("color", "white");
      // 可以将滚动位置定制为合适的位置
      // const targetElement = document.querySelector(href);
      // window.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
    },
    truncateString(str, maxLength) {
      if (str) {
        // 如果字符串长度小于或等于最大长度，直接返回原字符串
        if (str.length <= maxLength) {
          return str;
        }
        // 获取字符串的前半部分和后半部分
        const prefix = str.slice(0, maxLength - 3);
        const suffix = str.slice(str.length - 3);
        // 拼接并返回省略中间部分的字符串
        return prefix + "..." + suffix;
      }
    },
    openurl(url) {
      window.open(url, "_blank");
    },
    out() {
      removeToken();
      removeUId();
      removeRole();
      removeCode();
      removeIsConnected();
      console.log("退出");
      this.address = "";
      this.Invitecode = "*****";
    },
    async disconnectwl() {
      if (window.phantom) {
        window.phantom.solana.disconnect();
        this.out();
      } else if (window.okxwallet) {
        if (window.phantom) {
          return;
        }
        window.okxwallet.solana.disconnect();
        this.out();
      }
    },
    async connectwallet() {
      //   modal.open();
      //   window.phantom.solana.signIn().then((res) => {
      //     console.log(res);
      //   });
      if (window.phantom) {
        const cnres = await window.phantom.solana.connect();
        if (cnres.publicKey) {
          const pbaddress = cnres.publicKey.toBase58();
          const provider = window.phantom?.solana;
          const original_msg = `As fren of PEPE, his excellency(h) HORSH is the undisputed meme
captain with a distinctive buck-toothed grin and a unique way of speaking
that turns "Horse" into "Horsh." A chatterbox at heart, he loves reggae music
and never misses a chance to boast about his incredible race achievements. His
catchphrase? “I am the only unparalleled, dazzling horsh you can't deny ”`;
          const { signature, publicKey } = await window.solana.signMessage(
            new TextEncoder().encode(original_msg),
            "utf8"
          );
          console.log(signature, publicKey);
          this.loginAccount(pbaddress, original_msg, signature);
        }
      }

      if (window.okxwallet) {
        if (window.phantom) {
          return;
        }
        const cnres = await window.okxwallet.solana.connect();
        if (cnres.publicKey) {
          const pbaddress = cnres.publicKey.toBase58();
          const provider = window.okxwallet?.solana;
          const original_msg = `As fren of PEPE, his excellency(h) HORSH is the undisputed meme
captain with a distinctive buck-toothed grin and a unique way of speaking
that turns "Horse" into "Horsh." A chatterbox at heart, he loves reggae music
and never misses a chance to boast about his incredible race achievements. His
catchphrase? “I am the only unparalleled, dazzling horsh you can't deny ”`;
          const { signature, publicKey } =
            await window.okxwallet.solana.signMessage(
              new TextEncoder().encode(original_msg),
              "utf8"
            );
          console.log(signature, publicKey);
          this.loginAccount(pbaddress, original_msg, signature);
        }
      }

      //   fetch("/backend", {
      //     method: "POST",

      //     body: JSON.stringify({
      //       public_key: publicKey.toBase58(),

      //       signature: bs58.encode(signature),
      //     }),
      //   });
    },
    loginAccount(address, original_msg, signature) {
      Login({
        address,
        original_msg,
        signature_hex: JSON.stringify(signature),
      }).then((res) => {
        console.log(res);
        setToken(res.data.accessToken);
        setRole(res.data.address);
        setUId(res.data.User_id);
        setMoney(res.data.sol_amount);
        setIsConnected("1");
        this.address = address;
        if (res.data.code != "") {
          setCode(res.data.code);
          this.Invitecode = res.data.code;
        }
      });
    },
    getcode() {
      if (getToken() == undefined || this.invitation == "") {
        return;
      } else {
        if (this.invitation2 == this.invitation) {
          return;
        } else {
          this.invitation2 = this.invitation;
          postInvitecode({
            Code: this.invitation,
            user_id: getUId(),
          }).then((res) => {
            setCode(res.data.code);
            this.Invitecode = res.data.accessToken;
          });
        }
      }
    },
    async copyInvitecode() {
      if (this.Invitecode == "*****") {
        return;
      } else {
        try {
          await navigator.clipboard.writeText(this.Invitecode);
          Message({
            message: "Success",
            type: "success",
            duration: 3 * 1000,
          });
          // 可以在这里添加复制成功的提示
        } catch (err) {
          Message({
            message: "error",
            type: "error",
            duration: 3 * 1000,
          });
        }
      }
    },
    async copyAddress() {
      copyString(this.address, () => {});
      //   try {
      //     await navigator.clipboard.writeText(this.address);
      //     Message({
      //       message: "Copied",
      //       type: "success",
      //       duration: 3 * 1000,
      //     });
      //     // 可以在这里添加复制成功的提示
      //   } catch (err) {
      //     Message({
      //       message: "error",
      //       type: "error",
      //       duration: 3 * 1000,
      //     });
      //   }
    },
    async copyInvitecode2() {
      copyString("A9wXx6PC5LfeBV6ba8zUb4GzLbVoTfmJ58Fywa9Qwave", () => {});
      //   try {
      //     await navigator.clipboard.writeText(
      //       "6z3kAFzN9rZ14h5qfTmEk4VvCepr1tVot6uXxWxjdU6K"
      //     );
      //     Message({
      //       message: "Success",
      //       type: "success",
      //       duration: 3 * 1000,
      //     });
      //     // 可以在这里添加复制成功的提示
      //   } catch (err) {
      //     Message({
      //       message: "error",
      //       type: "error",
      //       duration: 3 * 1000,
      //     });
      //   }
    },
  },
};
</script>
<style scoped>
.email {
  height: 20rem;
  background: #000;
  color: #fff;
  font-size: 6rem;
  text-align: center;
  line-height: 20rem;
  padding: 0 10rem;
}
.pagefooter-box2 img {
  width: 9rem !important;
  height: 9rem;
}
.pagefooter-box2 {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: flex-end;
  transform: translate3d(0, -32rem, 0);
}
.pagefooter-back {
  display: block;
  width: 80%;
  margin: 0 auto;
  transform: translate3d(0, -12rem, 0);
}
.pagefooter {
  background: #5500ba;
}
.addressbox {
  font-size: 4.2rem;
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
  display: flex;
  align-items: center;
  padding: 0 4rem;
  background: #5500ba;
  font-family: Thunder;
  font-weight: bold;
}
.connectedbox {
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding-top: 5rem;
}
@font-face {
  font-family: "font";
  src: url("../static/font/font.ttf") format("truetype");
}
@font-face {
  font-family: "Thunder";
  src: url("../static/font/Thunder-LC.ttf") format("truetype");
}
a {
  text-decoration: none;
}

.homepage {
  font-family: "font";
  font-size: 1.6rem;
  background-color: rgb(110, 145, 221);
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 6rem;
}

/* 当屏幕宽度大于或等于768像素时，改为浮动布局 */
@media (min-width: 768px) {
  .homepage {
    min-width: 190rem;
  }
  .header {
    min-width: 190rem;
  }
}
@media (max-width: 768px) {
  .img7 {
    top: 18% !important;
  }
}

.footer {
  color: #fff;
  text-align: center;
  font-size: 3rem;
  padding: 5rem;
}

.box1 {
  margin-left: 10rem;
}
.img7top {
  position: absolute;
  top: 84%;
  left: 73%;
  font-style: italic;
  font-weight: bold;
  font-size: 6rem;
}
.img7 {
  position: absolute;
  top: 90%;
  left: 70%;
  width: 55rem;
  height: 18rem;
  background-image: url(../static/img/img8.png);
  background-size: cover;
  font-weight: normal;
  font-size: 6rem;
  color: #000000;
  line-height: 6rem;
  font-style: italic;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img7 p {
  margin-bottom: 2rem;
}

.img7 i {
  cursor: pointer;
  color: #cccccc;
}

.box2 {
  margin-right: 10rem;
  display: flex;
  align-items: center;
}

.box2 a {
  margin: 0 2rem;
  font-style: italic;
}

.img1 {
  width: 40rem;
  height: 11rem;
  cursor: pointer;
}

.img2 {
  width: 6rem;
  height: 6rem;
  margin: 0 0.3rem;
}

.text1 {
  color: black;
}

.text2 {
  color: white;
}

.text3 {
  color: white;
}

.text4 {
  color: white;
}

.text5 {
  color: white;
}

.text0 {
  cursor: pointer;
}

.bigima1 {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.bigima1 img {
  margin-top: 13rem;
  width: 110%;
}

.box3 {
  width: 100%;
  position: relative;
}

.box31 {
  margin-left: 9rem;
  margin-top: -14rem;
}

.box31 div {
  font-family: fantasy;
  font-size: 2.8rem;
  color: white;
  font-style: italic;
  line-height: 5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.bigima2 {
  width: 40%;
}

.bigima3 {
  width: 55%;
  position: absolute;
  top: -27rem;
  right: 12rem;
}

.box4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dddddd;
  padding-top: 2rem;
  padding-bottom: 10rem;
}

.bigima4 {
  width: 90%;
  z-index: 1;
}

.box41 {
  width: 70%;
  display: flex;
  align-items: center;
}

.box42 {
  margin-top: -26rem;
  z-index: 2;
}

.box411 {
  height: 30rem;
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 5rem;
  position: relative;
}

.box411 img {
  height: 30rem;
  margin: auto;
}

.box412 {
  flex: 3;
}

.box412 img {
  height: 11rem;
  margin: auto;
}

.box412 p {
  margin-top: 2rem;
  font-family: math;
  line-height: 4rem;
  font-size: 3rem;
  font-style: italic;
}
.box412 i {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.box5 {
  padding: 0 10rem;
  background-color: black;
}

.bigimg5 {
  width: 100%;
  margin-top: -10rem;
  position: relative;
  z-index: 1;
}

.box51 {
  margin: -10rem 7rem 10rem;
  background: rgba(82, 82, 82, 0.66);
}

.box511 {
  width: 100%;
  background: rgba(82, 82, 82, 0.66);
  position: relative;
}

.box5111 {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.box5111 p {
  width: 44rem;
  height: 11rem;
  margin-top: 2rem;
  line-height: 11rem;
  font-size: 6rem;
  background-color: #fff;
  padding-left: 3rem;
  font-style: italic;
}

.box5111 img {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 5rem;
  right: -8rem;
}

.box5112 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box51121 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  padding-top: 6rem;
}

.box51121 input {
  width: 60%;
  height: 11rem;
  font-size: 6rem;
  line-height: 60px;
  text-align: center;
  font-family: math;
}

.box51121 input:focus {
  outline: none;
}

.box51121 input::placeholder {
  color: #cccccc;
  font-family: math;
  font-style: italic;
}

.box51121 p {
  width: 30%;
  height: 11rem;
  font-size: 8rem;
  color: #ffff00;
  line-height: 11rem;
  font-style: italic;
  background: #5500ba;
  text-align: center;
}

.box51122 {
  padding: 8rem 0 5rem;
  font-size: 4rem;
  color: #ffffff;
  font-style: italic;
  font-family: math;
}

.box51123 {
  display: flex;
  align-items: center;
}

.box51123 p {
  font-size: 12rem;
  color: #ffffff;
  font-style: italic;
}

.box51123 div {
  margin-left: 5rem;
  padding: 1rem 4rem 1rem 3rem;
  font-size: 10rem;
  line-height: 10rem;
  color: #cccccc;
  font-style: italic;
  background: #ebf7fc;
}
.myteam-btn {
  background: #5500ba !important;
  color: #ffff00 !important;
  cursor: pointer;
}
.box51124 {
  padding: 5rem 1rem;
  font-size: 3.6rem;
  color: #ffffff;
  line-height: 3.6rem;
  font-style: italic;
  font-family: math;
}

.box52 {
  height: 1rem;
}

.box6 {
  padding: 0 10rem;
  background: #5500ba;
}

.bigimg6 {
  width: 100%;
  margin-top: -15rem;
  position: relative;
  z-index: 1;
}

.box61 {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
}

.bigimg7 {
  position: absolute;
  top: -13%;
  left: 0rem;
  width: 60%;
}

.box6111 {
  font-size: 5rem;
  line-height: 5rem;
  font-style: italic;
}

.box611 {
  margin-top: 3rem;
  width: 60%;
  background: #ebf7fc;
  padding: 6rem 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.box6111 {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  width: 70rem;
}

.box6111 p {
  margin: 0 4rem;
  width: 10rem;
}

.box61111 {
  width: 3.6rem;
  height: 3.6rem;
  background: #fdb869;
}

.box61112 {
  width: 3.6rem;
  height: 3.6rem;
  background: #eced1f;
}

.box61113 {
  width: 3.6rem;
  height: 3.6rem;
  background: #ebe66f;
}

.box61114 {
  width: 3.6rem;
  height: 3.6rem;
  background: #5100b6;
}

.box61115 {
  width: 3.6rem;
  height: 3.6rem;
  background: #4638e7;
}

.box61116 {
  width: 3.6rem;
  height: 3.6rem;
  background: #9c2dd3;
}

.box61117 {
  width: 3.6rem;
  height: 3.6rem;
  background: #ef6af8;
}

.box612 {
  padding: 5rem 0 26rem;
  display: flex;
  align-items: end;
}

.box612 img {
  width: 9rem;
  height: 9rem;
}
</style>
