import { Message } from "element-ui";
export function copyString(str, fn) {
  // 创建一个 textarea 元素
  const textarea = document.createElement("textarea");
  // 将字符串设置为 textarea 的值
  textarea.value = str;
  // 将 textarea 元素添加到文档中
  document.body.appendChild(textarea);
  // 选择 textarea 中的文本
  textarea.select();
  // 尝试复制选中的文本
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      Message({
        message: "Copied",
        type: "success",
        duration: 3 * 1000,
      });
    } else {
      Message({
        message: "Copied failed",
        type: "error",
        duration: 3 * 1000,
      });
    }
  } catch (error) {
    console.error("复制失败:", error);
  }
  // 从文档中移除 textarea 元素
  document.body.removeChild(textarea);
}
