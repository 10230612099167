import request from "@/utils/request";

// {
//      user_id
//   }
export function getuserinfo(user_id) {
  return request({
    url: "/v1/user/select?user_id=" + user_id,
    method: "get",
  });
}

// {
//     "address": "string",
//     "user_id": "string"
//   }
export function postsearch(data) {
  return request({
    url: "/v1/user/Solsearch",
    method: "post",
    data,
  });
}
