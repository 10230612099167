/*
 * @Author: “mxmxmx” “2454023350@qq.com”
 * @Date: 2024-07-25 14:07:54
 * @LastEditors: “mxmxmx” “2454023350@qq.com”
 * @LastEditTime: 2024-07-27 11:44:14
 * @FilePath: /horsh-web/src/utils/auth.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Cookies from "js-cookie";
const TokenKey = "vue_admin_template_token";
const UId = "user_id";
const Permission = "user_per";
const Role = "role";
const Code = "code";
const Money = "money";
const IsConnected = "isconnected";

export function getMoney() {
  return Cookies.get(Money);
}

export function setMoney(money) {
  return Cookies.set(Money, money);
}
export function removeMoney() {
  return Cookies.remove(Money);
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getRole() {
  return Cookies.get(Role);
}

export function setRole(role) {
  return Cookies.set(Role, role);
}
export function removeRole() {
  return Cookies.remove(Role);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUId() {
  return localStorage.getItem(UId);
}

export function setUId(id) {
  return localStorage.setItem(UId, id);
}

export function removeUId() {
  return localStorage.removeItem(UId);
}
export function getPermission() {
  let _permission = localStorage.getItem(Permission);
  _permission ? (_permission = JSON.parse(_permission)) : "";
  return _permission;
}

export function setPermission(permission) {
  return localStorage.setItem(Permission, permission);
}

export function removePermission() {
  return localStorage.removeItem(Permission);
}
export function getCode() {
  return Cookies.get(Code);
}

export function setCode(role) {
  return Cookies.set(Code, role);
}
export function removeCode() {
  return Cookies.remove(Code);
}

export function getIsConnected() {
  return Cookies.get(IsConnected);
}

export function setIsConnected(is_connected) {
  return Cookies.set(IsConnected, is_connected);
}
export function removeIsConnected() {
  return Cookies.remove(IsConnected);
}
