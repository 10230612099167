<template>
  <div>
    <h2>Solana Token Transfer</h2>
    <p>Wallet Address: {{ walletAddress }}</p>
    <button @click="connectWallet">Connect Wallet</button>
    <button @click="transferToken" :disabled="!walletAddress">
      Transfer Token
    </button>
  </div>
</template>

<script>
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
} from "@solana/web3.js";
import {
  Token,
  TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
} from "@solana/spl-token";

export default {
  data() {
    return {
      connection: null,
      walletAddress: null,
      tokenAddress: "CHybcpyyV2BHFpCXc1G7pzAghCwvMHkCbYDJtk8QxMcz",
      recipientAddress: "6LZjCvUnhrWR9JKTh4W8YmWV9yd3VcVvMLtAPdQKnYQE",
    };
  },
  created() {
    this.connection = new Connection(clusterApiUrl("devnet"), "confirmed");
  },
  methods: {
    async connectWallet() {
      if (window.solana && window.solana.isPhantom) {
        try {
          const response = await window.solana.connect();
          this.walletAddress = response.publicKey
            ? response.publicKey.toString()
            : null;
          console.log("Wallet Address:", this.walletAddress);
        } catch (err) {
          console.error("Wallet connection failed", err);
        }
      } else {
        console.error("Solana wallet not found");
      }
    },
    // async transferToken() {
    //   if (!this.walletAddress) {
    //     alert("Please connect your wallet first!");
    //     return;
    //   }

    //   try {
    //     const fromPublicKey = new PublicKey(this.walletAddress);
    //     const toPublicKey = new PublicKey(this.recipientAddress);
    //     const tokenPublicKey = new PublicKey(this.tokenAddress);

    //     const fromTokenAccount = await getAssociatedTokenAddress(
    //       tokenPublicKey,
    //       fromPublicKey
    //     );

    //     let toTokenAccount = await getAssociatedTokenAddress(
    //       tokenPublicKey,
    //       toPublicKey
    //     );
    //     const toTokenAccountInfo = await this.connection.getTokenAccountBalance(
    //       toTokenAccount
    //     );
    //     console.log("To Token Account Balance:", toTokenAccountInfo);
    //     // 打印 Token 账户地址
    //     console.log("fromTokenAccount:", fromTokenAccount.toBase58());
    //     console.log("toTokenAccount:", toTokenAccount.toBase58());

    //     // 检查目标账户是否存在，如果不存在则创建
    //     const accountInfo = await this.connection.getAccountInfo(
    //       toTokenAccount
    //     );
    //     if (accountInfo === null) {
    //       console.log("Creating associated token account...");

    //       const transaction = new Transaction().add(
    //         createAssociatedTokenAccountInstruction(
    //           fromPublicKey,
    //           toTokenAccount,
    //           toPublicKey,
    //           tokenPublicKey
    //         )
    //       );

    //       const { blockhash } = await this.connection.getLatestBlockhash();
    //       transaction.recentBlockhash = blockhash;
    //       transaction.feePayer = fromPublicKey;

    //       const signedTransaction = await window.solana.signTransaction(
    //         transaction
    //       );

    //       // 打印签名信息
    //       signedTransaction.signatures.forEach((sig) => {
    //         console.log(
    //           "Signature for publicKey",
    //           sig.publicKey.toString(),
    //           ":",
    //           sig.signature
    //         );
    //       });

    //       const signature = await this.connection.sendRawTransaction(
    //         signedTransaction.serialize(),
    //         { skipPreflight: false, preflightCommitment: "confirmed" }
    //       );
    //       await this.connection.confirmTransaction(signature, "confirmed");
    //     }

    //     // 确保账户已经创建后再发起转账
    //     const transferTransaction = new Transaction().add(
    //       createTransferInstruction(
    //         fromTokenAccount,
    //         toTokenAccount,
    //         fromPublicKey,
    //         [],
    //         BigInt(800 * Math.pow(10, 8)) // 1 Token with precision of 6 (adjust if needed)
    //       )
    //     );

    //     const { blockhash } = await this.connection.getLatestBlockhash();
    //     transferTransaction.recentBlockhash = blockhash;
    //     transferTransaction.feePayer = fromPublicKey;

    //     const signedTransferTransaction = await window.solana.signTransaction(
    //       transferTransaction
    //     );

    //     // 打印签名信息
    //     signedTransferTransaction.signatures.forEach((sig) => {
    //       console.log(
    //         "Transfer Signature for publicKey",
    //         sig.publicKey.toString(),
    //         ":",
    //         sig.signature
    //       );
    //     });

    //     const transferSignature = await this.connection.sendRawTransaction(
    //       signedTransferTransaction.serialize(),
    //       { skipPreflight: false, preflightCommitment: "confirmed" }
    //     );
    //     const confirmation = await this.connection.confirmTransaction(
    //       transferSignature,
    //       "confirmed"
    //     );

    //     console.log("Transaction confirmation status:", confirmation);

    //     console.log("Transaction Signature:" + transferSignature);
    //     alert("Transfer successful!  ");
    //   } catch (err) {
    //     console.error("Transfer failed", err);
    //     alert("Transfer failed: " + err.message);
    //   }
    // },
    async transferToken() {
      if (!this.walletAddress) {
        alert("Please connect your wallet first!");
        return;
      }

      try {
        const fromPublicKey = new PublicKey(this.walletAddress);
        const toPublicKey = new PublicKey(this.recipientAddress);
        const tokenPublicKey = new PublicKey(this.tokenAddress);

        const fromTokenAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          fromPublicKey
        );

        let toTokenAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          toPublicKey
        );

        const fromTokenBalanceBefore =
          await this.connection.getTokenAccountBalance(fromTokenAccount);
        const toTokenBalanceBefore =
          await this.connection.getTokenAccountBalance(toTokenAccount);

        console.log(
          "From Token Account Balance before transfer:",
          fromTokenBalanceBefore.value.uiAmount
        );
        console.log(
          "To Token Account Balance before transfer:",
          toTokenBalanceBefore.value.uiAmount
        );

        // 检查目标账户是否存在，如果不存在则创建
        const accountInfo = await this.connection.getAccountInfo(
          toTokenAccount
        );
        if (accountInfo === null) {
          console.log("Creating associated token account...");

          const transaction = new Transaction().add(
            createAssociatedTokenAccountInstruction(
              fromPublicKey,
              toTokenAccount,
              toPublicKey,
              tokenPublicKey
            )
          );

          const { blockhash } = await this.connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = fromPublicKey;

          const signedTransaction = await window.solana.signTransaction(
            transaction
          );

          const signature = await this.connection.sendRawTransaction(
            signedTransaction.serialize(),
            { skipPreflight: false, preflightCommitment: "confirmed" }
          );

          await this.connection.confirmTransaction(signature, "confirmed");
        }

        // 确保账户已经创建后再发起转账
        const transferTransaction = new Transaction().add(
          createTransferInstruction(
            fromTokenAccount,
            toTokenAccount,
            fromPublicKey,
            [],
            BigInt(1 * Math.pow(10, 8)) // 1 Token with precision of 8 (adjust if needed)
          )
        );

        const { blockhash } = await this.connection.getLatestBlockhash();
        transferTransaction.recentBlockhash = blockhash;
        transferTransaction.feePayer = fromPublicKey;

        const signedTransferTransaction = await window.solana.signTransaction(
          transferTransaction
        );

        // 打印签名信息
        signedTransferTransaction.signatures.forEach((sig) => {
          console.log(
            "Transfer Signature for publicKey",
            sig.publicKey.toString(),
            ":",
            sig.signature
          );
        });

        const transferSignature = await this.connection.sendRawTransaction(
          signedTransferTransaction.serialize(),
          { skipPreflight: false, preflightCommitment: "confirmed" }
        );

        // 确认交易并检查结果
        const confirmation = await this.connection.confirmTransaction(
          transferSignature,
          "confirmed"
        );

        if (confirmation.value.err) {
          throw new Error(
            "Transaction failed with error: " + confirmation.value.err
          );
        }

        // 再次检查余额
        const fromTokenBalanceAfter =
          await this.connection.getTokenAccountBalance(fromTokenAccount);
        const toTokenBalanceAfter =
          await this.connection.getTokenAccountBalance(toTokenAccount);

        console.log(
          "From Token Account Balance after transfer:",
          fromTokenBalanceAfter.value.uiAmount
        );
        console.log(
          "To Token Account Balance after transfer:",
          toTokenBalanceAfter.value.uiAmount
        );

        alert("Transfer successful!");
      } catch (err) {
        console.error("Transfer failed", err);
        alert("Transfer failed: " + err.message);
      }
    },
  },
};
</script>
